const msal_browser = require("@azure/msal-browser");

// Config object to be passed to Msal on creation
let msalConfig = {
    auth: {
        // ToDo: konfigurierbar machen
        clientId: process.env.VUE_APP_AUTH_CLIENTID,
        authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AUTH_TENANT_ID}`,
        redirectUri: process.env.VUE_APP_AUTH_REDIRECT_UTL,
        postLogoutRedirectUri: "/", // Must be registered as an SPA redirectURI on your app registration
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: function (level, message, containsPii) {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal_browser.LogLevel.Error:
                        console.error(message);
                        return;
                    case msal_browser.LogLevel.Info:
                        console.info(message);
                        return;
                    case msal_browser.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case msal_browser.LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            piiLoggingEnabled: false,
            logLevel: msal_browser.LogLevel.Verbose,
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
    },
};

exports.msalInstance = new msal_browser.PublicClientApplication(
    msalConfig
);


// Add here scopes for id token to be used at MS Identity Platform endpoints.
exports.loginRequest = {
    scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
exports.graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
