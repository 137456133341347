import { createRouter, createWebHashHistory } from "vue-router";
import { registerGuard } from "@/authentication/Guard.js";
import UserRights from "@/authentication/UserRights";
import Profile from "@/components/profile/Profile.vue";
import AddClientStep from "@/components/clientManagement/addClient/AddClientStep.vue";

const routes = [
  {
    path: "/",
    name: "Homepage",
    exact: true,
    component: () => import("./Homepage.vue"),
    meta: {
      breadcrumb: { label: "Homepage" },
      requiresAuth: true,
    },
  },
  {
    path: "/OrderHardware",
    name: "OrderHardware",
    component: () =>
      import("./components/hardwaremanagement/OrderHardware.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/notfound",
    name: "notfound",
    component: () => import("./pages/NotFound.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/employeeOffBoarding",
    name: "employeeOffBoarding",
    component: () =>
      import("./components/employeeManagement/offBoarding/OffBoarding.vue"),
    meta: {
      breadcrumb: {
        parent: "EmployeeManagementHome",
        label: "breadcrumbs.employeeManagement.offboarding",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/illness-Report",
    name: "illness-Report",
    component: () => import("./view-pages/illnessReport.vue"),
    meta: {
      breadcrumb: { label: "illnessReport.illness" },
    },
  },
  {
    path: "/hardwareManagement",
    name: "hardwareManagement",
    component: () =>
      import("./components/hardwaremanagement/assignHardware.vue"),
    meta: {
      breadcrumb: { label: "breadcrumbs.hardwareManagement" },
      requiresAuth: true,
    },
  },
  {
    path: "/EmployeeOnboardingMenu",
    name: "EmployeeOnboardingMenu",
    component: () =>
      import(
        "./components/employeeManagement/createEmployee/EmployeeOnboardingMenu.vue"
      ),
  },
  {
    path: "/AccountingData",
    name: "AccountingData",
    component: () =>
      import(
        "./components/employeeManagement/createEmployee/AccountingData.vue"
      ),
  },
  {
    path: "/ContractData",
    name: "ContractData",
    component: () =>
      import("./components/employeeManagement/createEmployee/ContractData"),
  },
  {
    path: "/MultiStepForm1",
    name: "MultiStepForm1",
    component: () =>
      import("./components/employeeManagement/createEmployee/MultiStepForm1"),
    meta: {
      breadcrumb: {
        parent: "EmployeeManagementHome",
        label: "breadcrumbs.employeeManagement.onboarding",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/OverviewOfEmployee",
    name: "OverviewOfEmployee",
    component: () =>
      import(
        "./components/employeeManagement/overviewEmployees/OverviewOfEmployee.vue"
      ),
    meta: {
      breadcrumb: {
        parent: "EmployeeManagementHome",
        label: "breadcrumbs.employeeDataManagement.employeeData",
      },
    },
  },
  {
    path: "/CreateButtonForm",
    name: "CreateButtonForm",
    component: () =>
      import("./components/widgetsStartingPage/CreateButtonForm"),
    meta: {
      breadcrumb: { label: "CreateButtonForm" },
      requiresAuth: true,
    },
  },
  {
    path: "/DeleteButtonForm",
    name: "DeleteButtonForm",
    component: () =>
      import("./components/widgetsStartingPage/DeleteButtonForm"),
    meta: {
      breadcrumb: { label: "DeleteButtonForm" },
      requiresAuth: true,
    },
  },
  {
    path: "/TimeTrackingHome",
    name: "TimeTrackingHome",
    component: () => import("./components/timetracking/TimeTrackingHome.vue"),
    meta: {
      breadcrumb: { parent: "Homepage", label: "breadcrumbs.timeTracking" },
      requiresAuth: true,
    },
  },
  {
    path: "/Training",
    name: "Training",
    component: () => import("./components/training/TrainingHome"),
    meta: {
      breadcrumb: { parent: "Homepage", label: "breadcrumbs.training.home" },
      requiresAuth: true,
    },
  },
  {
    path: "/Training/list",
    name: "Übersicht",
    component: () => import("./components/training/TrainingOverview"),
    meta: {
      breadcrumb: { parent: "Training", label: "breadcrumbs.training.list" },
      requiresAuth: true,
    },
  },
  {
    path: "/Training/Request",
    name: "TrainingRequest",
    component: () => import("./components/training/TrainingRequest"),
    meta: {
      breadcrumb: { parent: "Training", label: "breadcrumbs.training.request" },
      requiresAuth: true,
    },
  },
  {
    path: "/Training/UploadCertificate",
    name: "UploadCertificate",
    component: () => import("./components/training/UploadCertificate"),
    meta: {
      breadcrumb: {
        parent: "Training",
        label: "breadcrumbs.training.uploadCertificate",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/vacation",
    name: "Vacation",
    component: () => import("./components/vacation/Vacation.vue"),
    meta: {
      breadcrumb: { parent: "Homepage", label: "breadcrumbs.vacation" },
      requiresAuth: true,
    },
  },
  {
    path: "/Tasks",
    name: "Tasks",
    component: () => import("./components/tasks/Tasks.vue"),
    meta: {
      breadcrumb: { parent: "Homepage", label: "breadcrumbs.tasks.home" },
      requiresAuth: true,
    },
  },
  {
    path: "/AbsenceOverview",
    name: "AbsenceOverview",
    component: () => import("./components/calendar/AbsenceOverview.vue"),
    meta: {
      breadcrumb: {
        parent: "Homepage",
        label: "breadcrumbs.absence",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/Contract",
    name: "Contract",
    component: () =>
      import(
        "./components/employeeManagement/ChangeOfContractsEmployees/Contract"
      ),
    meta: {
      breadcrumb: {
        parent: "EmployeeManagementHome",
        label: "breadcrumbs.employeeManagement.contract",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/EmployeeManagementHome",
    name: "EmployeeManagementHome",
    component: () =>
      import(
        "./components/employeeManagement/overviewEmployees/EmployeeManagementHome"
      ),
    meta: {
      breadcrumb: { label: "breadcrumbs.employeeManagement.home" },
      requiresAuth: true,
    },
  },
  {
    path: "/ExpenseAccounting",
    name: "ExpenseAccounting",
    component: () =>
      import("./components/expenseAccounting/ExpenseAccounting.vue"),
    meta: {
      breadcrumb: {
        parent: "Home",
        label: "breadcrumbs.expenseAccounting.home",
      },
    },
  },
  {
    path: "/EmployeeOverview",
    name: "EmployeeOverview",
    component: () =>
      import(
        "./components/employeeManagement/overviewEmployees/EmployeeOverview.vue"
      ),
    meta: {
      breadcrumb: {
        parent: "EmployeeManagementHome",
        label: "breadcrumbs.employeeManagement.overview",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/ChangeEmployee",
    name: "ChangeEmployee",
    component: () =>
      import(
        "./components/employeeManagement/overviewEmployees/ChangeEmployee"
      ),
    meta: {
      breadcrumb: { parent: "ChangeEmployee", label: "ChangeEmployee" },
      requiresAuth: true,
    },
  },
  {
    path: "/EmployeeManagementPage",
    name: "EmployeeManagementPage",
    component: () =>
      import(
        "./components/employeeManagement/overviewEmployees/EmployeeManagementPage"
      ),
    meta: {
      breadcrumb: {
        parent: "EmployeeOverview",
        label: "EmployeeManagementPage",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/Profile/:userId",
    name: "Profile",
    component: () => Profile,
    meta: {
      breadcrumb: {
        parent: UserRights.isUserGroup(UserRights.OFFICE)
          ? "ProfileOverview"
          : "",
        label: "breadcrumbs.profile",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/ProfileOverview",
    name: "ProfileOverview",
    component: () => import("./components/profile/ProfileOverview.vue"),
    meta: {
      breadcrumb: { label: "breadcrumbs.profileOverview" },
      requiresAuth: true,
    },
  },
  {
    path: "/DisplayFiles",
    name: "DisplayFiles",
    component: () =>
      import("./components/employeeManagement/overviewEmployees/DisplayFiles"),
    meta: {
      breadcrumb: { parent: "EmployeeManagementHome", label: "DisplayFiles" },
      requiresAuth: true,
    },
  },
  {
    path: "/projectManagement",
    name: "projectManagement",
    component: () =>
      import("./components/projectManagement/projectManagement.vue"),
    meta: {
      breadcrumb: {
        parent: "Homepage",
        label: "breadcrumbs.projectManagement.home",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/project",
    name: "project",
    component: () =>
      import(
        "./components/projectManagement/createProject/createProjectStep.vue"
      ),
    meta: {
      breadcrumb: {
        parent: "projectManagement",
        label: "breadcrumbs.projectManagement.addProject",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/CreatePerformanceRecord",
    name: "CreatePerformanceRecord",
    component: () =>
      import("./components/performanceRecords/CreatePerformanceRecord.vue"),
    meta: {
      breadcrumb: {
        parent: "PerformanceRecordManagement",
        label: "performanceRecord.create",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/PerformanceRecords",
    name: "PerformanceRecords",
    component: () =>
      import("./components/performanceRecords/PerformanceRecords.vue"),
    meta: {
      breadcrumb: {
        parent: "PerformanceRecordManagement",
        label: "performanceRecord.upload",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/PerformanceRecordRegisterEmail",
    name: "PerformanceRecordRegisterEmail",
    component: () =>
      import(
        "./components/performanceRecords/PerformanceRecordRegisterEmail.vue"
      ),
    meta: {
      breadcrumb: {
        parent: "PerformanceRecordManagement",
        label: "performanceRecord.mailRegistration",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/PerformanceRecordManagement",
    name: "PerformanceRecordManagement",
    component: () =>
      import("./components/performanceRecords/PerformanceRecordManagement.vue"),
    meta: {
      breadcrumb: { parent: "Homepage", label: "performanceRecord.home" },
      requiresAuth: true,
    },
  },
  {
    path: "/ClientManagement",
    name: "ClientManagement",
    component: () =>
      import("./components/clientManagement/ClientManagement.vue"),
    meta: {
      breadcrumb: { label: "breadcrumbs.clientManagement.home" },
      requiresAuth: true,
    },
  },
  {
    path: "/client",
    name: "client",
    component:  AddClientStep,
    meta: {
      breadcrumb: {
        parent: "ClientManagement",
        label: "breadcrumbs.clientManagement.addClient",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/editClient/:clientId",
    name: "editClient",
    component: AddClientStep,
    meta: {
      breadcrumb: {
        parent: "ClientManagement",
        label: "breadcrumbs.clientManagement.addClient",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/AdminConfiguration",
    name: "AdminConfiguration",
    component: () => import("./components/AdminConfigs/AdminConfiguration.vue"),
    meta: {
      breadcrumb: {
        parent: "Homepage",
        label: "breadcrumbs.adminConfiguration",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/TimeTrackingConfiguration",
    name: "TimeTrackingConfiguration",
    component: () =>
      import("./components/AdminConfigs/TimeTrackingConfiguration.vue"),
    meta: {
      breadcrumb: {
        parent: "AdminConfiguration",
        label: "adminConfigurations.timeTrackingConfiguration",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/DataProtectionConfiguration",
    name: "DataProtectionConfiguration",
    component: () =>
      import("./components/AdminConfigs/DataProtectionConfiguration.vue"),
    meta: {
      breadcrumb: {
        parent: "AdminConfiguration",
        label: "adminConfigurations.dataProtectionConfiguration",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/DocumentOverview",
    name: "DocumentOverview",
    component: () => import("./components/AdminConfigs/DocumentOverview.vue"),
    meta: {
      breadcrumb: {
        parent: "AdminConfiguration",
        label: "adminConfigurations.documentOverview",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/VacationForOtherEmployeeConfiguration",
    name: "VacationForOtherEmployeeConfiguration",
    component: () =>
      import(
        "./components/AdminConfigs/VacationForOtherEmployeeConfiguration.vue"
      ),
    meta: {
      breadcrumb: {
        parent: "AdminConfiguration",
        label: "adminConfigurations.requestVacationForOtherEmployee",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/CreateAnnualLeaveConfiguration",
    name: "CreateAnnualLeaveConfiguration",
    component: () =>
      import("./components/AdminConfigs/CreateAnnualLeaveConfiguration.vue"),
    meta: {
      breadcrumb: {
        parent: "AdminConfiguration",
        label: "adminConfigurations.createAnnualLeave",
      },
      requiresAuth: true,
    },
  },
  {
    path: "/birthdayCalendar",
    name: "birthdayCalendar",
    component: () => import("./components/calendar/BirthdayCalendar.vue"),
    meta: {
      breadcrumb: {
        parent: "Report",
        label: "breadcrumbs.birthdayCalendar",
      },
    },
  },
  {
    path: "/ConsentForm",
    name: "ConsentForm",
    component: () => import("./components/dataprotection/ConsentForm"),
    meta: {
      breadcrumb: { parent: "Homepage", label: "breadcrumbs.dataProtection" },
      requiresAuth: true,
    },
  },
  {
    path: "/report",
    name: "report",
    component: () => import("./components/report/Report.vue"),
    meta: {
      breadcrumb: {
        parent: "Homepage",
        label: "breadcrumbs.report",
      },
    },
  },
  {
    path: "/VacationReport",
    name: "VacationReport",
    component: () => import("./components/report/VacationReport.vue"),
    meta: {
      breadcrumb: {
        parent: "report",
        label: "report.vacationReport",
      },
    },
  },
  {
    path: "/TimeTrackingReport",
    name: "TimeTracking",
    component: () => import("./components/report/TimeTracking.vue"),
    meta: {
      breadcrumb: {
        parent: "report",
        label: "report.timeTrackingReport",
      },
    },
  },
  {
    path: "/CashFlow",
    name: "CashFlow",
    component: () => import("./components/report/CashFlow.vue"),
    meta: {
      breadcrumb: {
        parent: "report",
        label: "report.cashFlow",
      },
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

registerGuard(router);

export default router;
