import {Store} from "@/service/Store";
import {Profile} from "@/authentication/Profile";
export default {
    EMPLOYEE: 0,
    OFFICE: 1,
    LEADER: 2,
    ADMIN: 3,

    getUserEmail() {
        this.readLocalStorage();
        return Store.userEmail;
    },

    getUserName() {
        this.readLocalStorage();
        return Store.userName;
    },

    getUserId() {
        return sessionStorage.getItem("userid");
    },

    getProfileUser() {
        return Store.profileID;
    },
    getUserGroup() {
        return Profile.getGroup();
    },
    isOrderHardware() {
        this.readLocalStorage();
        return Store.isOrderHardware;
    },
    getHardwareOrderId() {
        this.readLocalStorage();
        return Store.orderHardwareId;
    },
    setOrderHardware() {
        this.readLocalStorage();
        if (!Store.isOrderHardware) {
            Store.isLoggedIn = false;
            Store.isOrderHardware = true;
            this.writeLocalStorage();
        }
    },
    setOrderHardwareId(id) {
        if (id.length > 0) {
            Store.orderHardwareId = id;
            this.writeLocalStorage();
        } else this.readLocalStorage();
    },

    readLocalStorage() {
    },

    writeLocalStorage() {
    },

    getError() {
        this.readLocalStorage();
        return Store.error;
    },

    isLoggedIn() {
        this.readLocalStorage();
        return Store.isLoggedIn;
    },

    setLoggedIn() {
        Store.isLoggedIn = true;
        this.writeLocalStorage();
    },

    logOut() {
        sessionStorage.clear();
    },
    isUserGroup(group) {
        return this.getUserGroup() >= group;
    },
    strToUserGroup(group) {
        if (group === "EMPLOYEE") return this.EMPLOYEE;
        if (group === "OFFICE") return this.OFFICE;
        if (group === "LEADER") return this.LEADER;
        if (group === "ADMIN") return this.ADMIN;
    },
};
